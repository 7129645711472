var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col gap-2" }, [
    _c(
      "div",
      { staticClass: "w-full" },
      [
        _c("vs-input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'",
            },
          ],
          staticClass: "w-full",
          attrs: { label: _vm.$t("assunto") },
          model: {
            value: _vm.model.subject,
            callback: function ($$v) {
              _vm.$set(_vm.model, "subject", $$v)
            },
            expression: "model.subject",
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("subject"),
                expression: "errors.has('subject')",
              },
            ],
            staticClass: "text-danger text-sm",
          },
          [_vm._v(_vm._s(_vm.errors.first("subject")))]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "w-full" },
      [
        _c("label", { staticClass: "text-sm pl-1" }, [
          _vm._v(_vm._s(_vm.$t("conteudo"))),
        ]),
        _c("complex-editor", {
          attrs: {
            editor_data: _vm.model.body,
            "auto-open": "",
            "dont-close": "",
          },
          on: {
            "update:editor_data": function ($event) {
              return _vm.$set(_vm.model, "body", $event)
            },
          },
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("body"),
                expression: "errors.has('body')",
              },
            ],
            staticClass: "text-danger text-sm",
          },
          [_vm._v(_vm._s(_vm.errors.first("body")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }