import Rest from '../Rest'

export default class MissiveService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/missives'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  addEmailDestination(missiveId, emails) {
    return this.post(`/${missiveId}/destinations`, {
      emails: emails
    })
  }

  deleteDestination(missiveId, destinationId) {
    return this.delete(`/${missiveId}/destinations/${destinationId}`)
  }

  dispatch(missiveId) {
    return this.post(`/${missiveId}/dispatch`)
  }

  dispatchDestination(missiveId, destinations) {
    return this.post(`/${missiveId}/destinations/dispatch`, {
      destinations: destinations
    })
  }
}
