var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col gap-6" },
    [
      _c("emails-input", {
        ref: "emailsInput",
        attrs: {
          buttonText: "Adicionar",
          "clear-on-send": "",
          "import-url":
            _vm.baseUrl +
            "/storage/templates/educatena-modelo-importacao-destinatarios.xlsx",
          "import-file-name": "educatena-modelo-importacao-destinatarios.xlsx",
          "popup-title": "Importar Destinatários",
        },
        on: { tagsSent: _vm.add, importStudents: _vm.imported },
      }),
      _c(
        "div",
        [
          _vm.service
            ? _c("grid", {
                ref: "grid",
                attrs: {
                  hide_actions: false,
                  service: _vm.service,
                  route_grid_path: "/" + _vm.id + "/destinations/grid",
                  route_name: "missives",
                  column_formats: {
                    created_at: function (val) {
                      return _vm.$utils.format.date(val)
                    },
                    dispatched_at: function (val) {
                      return _vm.$utils.format.date(val)
                    },
                    status: function (val) {
                      switch (val) {
                        case null:
                          return "Não Enviado"
                        case "UNKNO":
                          return "Despachado"
                        case "CONFR":
                          return "Confirmado"
                        case "ERROR":
                          return "Erro"
                        default:
                          return ""
                      }
                    },
                  },
                  css_formats: {
                    status: function (val) {
                      switch (val) {
                        case null:
                          return "text-warning"
                        case "UNKNO":
                          return "text-primary"
                        case "CONFR":
                          return "text-success"
                        case "ERROR":
                          return "text-danger"
                        default:
                          return ""
                      }
                    },
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function (actions) {
                        return [
                          _vm.canDispatchDestination(actions.row)
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: "Enviar ao destinatário",
                                    expression: "'Enviar ao destinatário'",
                                  },
                                ],
                                attrs: {
                                  icon: "SendIcon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.dispatchDestination(
                                      actions.row.id
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.canDeleteDestination(actions.row)
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: "Remover destinatário",
                                    expression: "'Remover destinatário'",
                                  },
                                ],
                                attrs: {
                                  icon: "XIcon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDestination(actions.row.id)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  214817673
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }