<template>
  <div class="flex flex-col gap-2">
    
    <div class="w-full">
      <vs-input v-validate="'required'" class="w-full" :label="$t('assunto')" v-model="model.subject"/>
      <span class="text-danger text-sm" v-show="errors.has('subject')">{{
        errors.first('subject')
      }}</span>
    </div>

    <div class="w-full">
      <label class="text-sm pl-1">{{ $t('conteudo') }}</label>
      <complex-editor
        v-bind:editor_data.sync="model.body"
        auto-open
        dont-close
      />
      <span class="text-danger text-sm" v-show="errors.has('body')">{{
        errors.first('body')
      }}</span>
    </div>
  </div>
</template>

<script>
import ComplexEditor from '../../questionnaires/elements/ComplexEditor.vue'
export default {
  components: { ComplexEditor },
  props: {
    model: {
      default: {
        id: null,
        subject: null,
        body: null
      },
      type: Object
    }
  }
}
</script>

<style>

</style>