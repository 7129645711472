<template>
  <div>
    <vs-tabs>
      <vs-tab :label="$t('dados')">
        <vx-card class="mb-4">
          <div class="flex flex-col gap-4">
            <div class="pb-8">
              <missive :model.sync="missive" class="pb-4"/>
              <div class="w-full flex gap-2 justify-end">
                <vs-button
                  :disabled="!canStoreMissive"
                  v-permission="'missives.create'"
                  @click="create"
                >
                  {{ $t('action.save') }}
                </vs-button>
                
                <vs-button
                  type="border"
                  @click="cancel"
                >
                  {{ $t('common.back') }}
                </vs-button>
              </div>
              <fieldset class="mt-4 border-faint-grey">
                <legend>
                  {{ $t('legenda') }}
                </legend>
                <vs-list>
                  <vs-list-item
                    title="{user_name}"
                    subtitle="Nome do usuário (se existir usuário com o email destinatário cadastrado no sistema)"
                  />
                  <vs-list-item
                    title="{user_email}"
                    subtitle="Email atual para qual está sendo enviado."
                  />
                  <vs-list-item
                    title="{institution_name}"
                    subtitle="Nome da instituição."
                  />
                </vs-list>
              </fieldset>
            </div>
          </div>
        </vx-card>
      </vs-tab>
      <vs-tab v-if="id" :label="$t('destinatarios')">
        <vx-card>
          <missive-destinations-form :id="id" :service="service"/>
          <div class="w-full flex gap-2 justify-end">

            <vs-button
              v-if="id"
              icon="send"
              color="success"
              @click="dispatch"
            >
              {{ $t('enviar') }}
            </vs-button>
            
            <vs-button
              type="border"
              @click="cancel"
            >
              {{ $t('common.back') }}
            </vs-button>
          </div>
        </vx-card>
      </vs-tab>
    </vs-tabs>
    

  </div>
</template>

<script>
import MissiveService from '@/services/api/MissiveService'
import Missive from './form/Missive.vue'
import MissiveDestinationsForm from './form/MissiveDestinationsForm.vue'
export default {
  components: { Missive, MissiveDestinationsForm },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    missive: {
      id: null,
      subject: null,
      body: function () {
        return this.$t('ola-less-than-b-greater-than-user_name-less-than-b-greater-than-a-less-than-b-greater-than-institution_name-less-than-b-greater-than-comunica-a-vosso-endereco-less-than-b-greater-than-user_email-less-than-b-greater-than-que-exemplo')
      },
      destinations_amount: 0,
      pending_dispatches_amount: 0
    },
  }),

  computed: {
    canStoreMissive() {
      return !this.isEmpty(this.missive.body) && !this.isEmpty(this.missive.subject)
    }
  },

  mounted() {
    this.service = MissiveService.build(this.$vs)

    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.missive = response
        },
        error => {

        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    create() {
      this.$vs.loading()
      this.service
        .createOrUpdate({
          id: this.missive.id,
          subject: this.missive.subject,
          body: this.missive.body
        })
        .then(
          data => {
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/missives/${data.id}/edit`)
          },
          error => {
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        ).finally(
          () => {this.$vs.loading.close()}
        )
    },
    dispatch() {

      // if (!this.canDispatchMissive()) {
      //   this.notifyWarning(this.$vs, "Não há destinatários nesta mala.")
      //   return
      // }
      
      this.$vs.loading()
      this.service.dispatch(this.missive.id).then(
        response => {
          this.notifySuccess(this.$vs, this.$t('os-envios-pendentes-serao-disparados'), 9000)
          this.$root.$emit('missive-dispatched')
        },
        error => {
          console.error(error)
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    cancel() {
      this.$router.push('/missives')
    },

    canDispatchMissive() {
      return this.missive.destinations_amount > 0
    }
  }
  
}
</script>

<style>

</style>