<template>
  <div class="flex flex-col gap-6">
    <emails-input
      ref="emailsInput"
      @tagsSent="add"
      @importStudents="imported"
      buttonText="Adicionar"
      clear-on-send
      :import-url="`${baseUrl}/storage/templates/educatena-modelo-importacao-destinatarios.xlsx`"
      import-file-name="educatena-modelo-importacao-destinatarios.xlsx"
      :popup-title="'Importar Destinatários'"
    />
    <div>
      <grid
        ref="grid"
        v-if="service"
        :hide_actions="false"
        :service="service"
        :route_grid_path="`/${id}/destinations/grid`"
        route_name="missives"
        :column_formats="{
          created_at: (val) => {
            return $utils.format.date(val)
          },
          dispatched_at: (val) => {
            return $utils.format.date(val)
          },
          status: (val) => {
            switch (val) {
            case (null):
              return 'Não Enviado'
            case ('UNKNO'):
              return 'Despachado'
            case ('CONFR'):
              return 'Confirmado'
            case ('ERROR'):
              return 'Erro'
            default:
              return ''
            }
          }
        }"
        :css_formats="{
          status: (val) => {
            switch (val) {
            case (null):
              return 'text-warning'
            case ('UNKNO'):
              return 'text-primary'
            case ('CONFR'):
              return 'text-success'
            case ('ERROR'):
              return 'text-danger'
            default:
              return ''
            }
          }
        }"
      >
        <template v-slot:actions="actions">
          <feather-icon
            v-tooltip="'Enviar ao destinatário'"
            v-if="canDispatchDestination(actions.row)"
            icon="SendIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
            @click="dispatchDestination(actions.row.id)"/>
          <feather-icon
            v-tooltip="'Remover destinatário'"
            v-if="canDeleteDestination(actions.row)"
            icon="XIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
            @click="deleteDestination(actions.row.id)"/>
        </template>
      </grid>
    </div>
  </div>
</template>

<script>
import EmailsInput from '@/components/EmailsInput.vue'
export default {
  components: { EmailsInput },

  props: {
    id: {
      default: null,
      type: Number
    },
    service: {
      default: null,
      type: Object
    }
  },

  data: () => ({
    updateGrid: null
  }),

  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_BASE_URL
    }
  },

  beforeMount() {
    this.updateGrid = this._.debounce(
      () => {
        const grid = this.$refs['grid']
        if (grid) {
          grid.fetchGridData()
        }
      }, 600
    )

    this.$root.$on('missive-dispatched', this.updateGrid)
  },

  beforeDestroy() {
    this.$root.$off('missive-dispatched', this.updateGrid)
  },

  methods: {

    imported(payload) {
      const emails = this._.map(payload, 'email')
      this.addEmails(emails)
    },
    add(list) {
      const emails = this._.map(list, 'text')
      this.addEmails(emails)
    },

    addEmails(emails) {
      if (this._.isArray(emails)) {
        this.addEmailDestination(emails)
      } else {
        console.log('invalid import/add')
      }
    },

    addEmailDestination(email) {
      this.$vs.loading()
      if (this.service && this.id) {
        this.service.addEmailDestination(this.id, email).then(
          response => {
            this.notifySuccess(this.$vs, this.$t('email-foi-adicionado-aos-destinatarios-com-sucesso', [email]))
          },
          error => {
            // this.notifyError(this.$vs, `Não foi possível adicionar ${email} aos destinatários!`)
          }
        ).finally(
          () => {
            this.$vs.loading.close()
            this.updateGrid()
          }
        )
      }
    },
    deleteDestination(id) {
      console.log('to delete destination', id)
      this.$vs.loading()
      this.service.deleteDestination(this.id, id).then(
        response => {
          this.updateGrid()
        },
        error => {
          this.updateGrid()
        }
      ).finally(
        () => this.$vs.loading.close()
      )
    },
    dispatchDestination(id) {
      console.log('To dispatch destination', id)
      this.$vs.loading()
      this.service.dispatchDestination(this.id, [id]).then(
        response => {
          this.updateGrid()
        },
        error => {
          this.updateGrid()
        }
      ).finally(
        () => this.$vs.loading.close()
      )
    },

    canDispatchDestination(destination) {
      const onError = destination.status === 'ERROR'
      const onPending = destination.dispatched_at === null

      return true//onError || onPending
    },
    canDeleteDestination(destination) {
      const pendingStatus = destination.status === null
      const onPending = destination.dispatched_at === null

      return true //pendingStatus || onPending
    }
  }
}
</script>

<style>

</style>
