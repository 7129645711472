var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-tabs",
        [
          _c(
            "vs-tab",
            { attrs: { label: _vm.$t("dados") } },
            [
              _c("vx-card", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "flex flex-col gap-4" }, [
                  _c(
                    "div",
                    { staticClass: "pb-8" },
                    [
                      _c("missive", {
                        staticClass: "pb-4",
                        attrs: { model: _vm.missive },
                        on: {
                          "update:model": function ($event) {
                            _vm.missive = $event
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "w-full flex gap-2 justify-end" },
                        [
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "missives.create",
                                  expression: "'missives.create'",
                                },
                              ],
                              attrs: { disabled: !_vm.canStoreMissive },
                              on: { click: _vm.create },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("action.save")) + " ")]
                          ),
                          _c(
                            "vs-button",
                            {
                              attrs: { type: "border" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "fieldset",
                        { staticClass: "mt-4 border-faint-grey" },
                        [
                          _c("legend", [
                            _vm._v(" " + _vm._s(_vm.$t("legenda")) + " "),
                          ]),
                          _c(
                            "vs-list",
                            [
                              _c("vs-list-item", {
                                attrs: {
                                  title: "{user_name}",
                                  subtitle:
                                    "Nome do usuário (se existir usuário com o email destinatário cadastrado no sistema)",
                                },
                              }),
                              _c("vs-list-item", {
                                attrs: {
                                  title: "{user_email}",
                                  subtitle:
                                    "Email atual para qual está sendo enviado.",
                                },
                              }),
                              _c("vs-list-item", {
                                attrs: {
                                  title: "{institution_name}",
                                  subtitle: "Nome da instituição.",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.id
            ? _c(
                "vs-tab",
                { attrs: { label: _vm.$t("destinatarios") } },
                [
                  _c(
                    "vx-card",
                    [
                      _c("missive-destinations-form", {
                        attrs: { id: _vm.id, service: _vm.service },
                      }),
                      _c(
                        "div",
                        { staticClass: "w-full flex gap-2 justify-end" },
                        [
                          _vm.id
                            ? _c(
                                "vs-button",
                                {
                                  attrs: { icon: "send", color: "success" },
                                  on: { click: _vm.dispatch },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("enviar")) + " ")]
                              )
                            : _vm._e(),
                          _c(
                            "vs-button",
                            {
                              attrs: { type: "border" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }